<nav class="absolute top-0 left-0 w-full px-10 max-md:px-2.5">
  <div class="mx-auto max-w-[1330px]">
    <div class="relative flex h-20 min-h-20 items-center justify-between max-lg:h-16">
      <!-- Mobile menu button -->
      <div class="absolute inset-y-0 left-0 flex items-center min-[1030px]:hidden">
        <button type="button"
                class="relative inline-flex items-center justify-center rounded-md p-2 text-[#1F2125] cursor-pointer z-10"
                aria-controls="mobile-menu" aria-expanded="false" aria-label="Open" i18n-aria-label="Open"
                (click)="toggleMenu()">
          <span class="absolute -inset-0.5"></span>
          <span class="sr-only" i18n>Open main menu</span>
          @if (!isMenuOpen) {
            <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                 aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
            </svg>
          } @else {
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                 aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          }
        </button>
      </div>

      <!-- Logo and menu -->
      <div class="flex flex-1 items-center justify-end sm:items-stretch min-[1029px]:justify-start">
        <div class="flex flex-shrink-0 items-center rounded-lg text-emerald-600">
          <a href="#">
            <img ngSrc="assets/images/logo-big.svg" alt="" height="38" width="241">
          </a>
        </div>
        <!-- Desktop menu -->
        <div class="hidden min-[1030px]:flex w-full">
          <div class="flex space-x-4 w-full justify-end">
            <app-navigation></app-navigation>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu -->
  <div class="absolute left-0 min-[1030px]:hidden z-10 right-0">
    @if (isMenuOpen) {
      <div class="space-y-1 px-2 pb-3 pt-2 bg-gradient-to-b from-[#7b8693] to-white border-[#ccd3da]">
        <app-navigation (menuItemClicked)="closeMenu()"></app-navigation>
      </div>
    }
  </div>
</nav>
